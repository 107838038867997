import apiTool from '@/command/apiTool'

function onOpen({ update, form = {} } = {}) {
  apiTool.showDrawer({
    title: '快递配置',
    formData: { ...form },
    width: '600px',
    form: [
      {
        name: '条款名称',
        key: 'name',
        type: 'input'
      },
      {
        name: '条款内容',
        key: 'content',
        type: 'textArea'
      }
    ],
    success: ({ data, setHidden }) => {
      update(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function delData({ del, form = {} } = {}) {
  del(form)
}

function createForm({ create }) {
  apiTool.showDrawer({
    title: '快递配置',
    formData: {},
    width: '600px',
    form: [
      {
        name: '条款名称',
        key: 'name',
        type: 'input'
      },
      {
        name: '条款内容',
        key: 'content',
        type: 'textArea'
      }
    ],
    success: ({ data, setHidden }) => {
      create(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

export default ({ update, del, create }) => {
  return {
    url: '/config/farmThirdTerms/list',
    edit: '/config/farmThirdTerms/update',
    del: '/config/farmThirdTerms/delete?id=',
    create: '/config/farmThirdTerms/add',
    type: 'table',
    props: {
      showFootButton: true,
      on: {
        handleAddData: () => createForm({ create })
      },
      columns: [
        {
          dataIndex: 'name',
          align: 'left'
        },
        {
          dataIndex: 'a1',
          tableType: 'editDelete',
          width: '200px',
          onEdit: (text, records) => onOpen({ update, form: records }),
          onDel: (text, records) => delData({ del, form: records })
        }
      ]
    }
  }
}
